export default class ProcessadorTelaTutorial {
    constructor({ processadorAtribuicao }) {
        this.processadorAtribuicao = processadorAtribuicao;
    }

    processarAtribuicoes({ dados, tela, atribuicoes }) {
        if (tela.atribuicoes === undefined || tela.atribuicoes === null) {
            return;
        }
        tela.atribuicoes.forEach((atribuicao) => {
            this.processadorAtribuicao.processar({
                atribuicao,
                dados,
                atribuicoes,
            });
        });
    }

    sobrescreverAtribuicoes({ atribuicoes }) {
        atribuicoes["fila"] = "N1::Autoatendimento";
        atribuicoes["state"] = "closed successful";
    }

    processar({ dados, tela, atribuicoes }) {
        this.processarAtribuicoes({ dados, tela, atribuicoes });
        if (dados.escolha === "sim") {
            this.sobrescreverAtribuicoes({ atribuicoes });
            return tela.telaSim;
        } else {
            return tela.telaNao;
        }
    }
}
