import axios from "axios";
import store from "@/vuex/store";
import { LOGIN } from "@/vuex/actions/autenticacao";

const baseURL = process.env.VUE_APP_ARVORE_URL;

/**
 * Classe Api
 * Responsável pela troca de mensagens com a API do projeto arvore via requisições HTTP pelo componente Axios
 * @author Guilherme de Oliveira
 * @documentação Axios: https://axios-http.com/docs/intro
 */
export default class Api {
    /**
     * @constructor
     * @atributes
     *  - http: AxiosInstance
     */
    constructor() {
        this.http = axios.create({
            baseURL: baseURL,
            headers: {},
        });

        this.http.interceptors.request.use((config) => {
            if (store.getters["autenticacao/isAuthenticated"]) {
                const token = store.getters["autenticacao/token"];
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });

        // this.http.interceptors.response.use((response) => {
        //     console.log("Response:", response);
        //     return response;
        // });

        this.http.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response.status === 403) {
                    store.dispatch("autenticacao/" + LOGIN);
                }
            }
        );
    }
    /**
     *  Obtem a lista com nome das arvores cadastradas
     *  @returns Array
     */
    getArvores() {
        return this.http
            .get("/api/arvores")
            .then((response) => response.data.arvores);
    }
    /**
     *  Obtem da API o JSON da arvore e converte para um objeto da classe Arvore
     *  @param nomeArvore: String
     *  @returns Arvore
     */
    getArvore(nomeArvore) {
        return this.http
            .get("/api/arvores/" + nomeArvore)
            .then((response) => response.data);
    }

    converterAnexos(anexos) {
        return anexos.map((arquivo) => {
            const index = arquivo.content.indexOf(",");

            return {
                contentType: arquivo.type,
                filename: arquivo.name,
                content: arquivo.content.substring(index + 1),
            };
        });
    }

    criarChamado(atribuicoes) {
        // {
        //     "propriedades": {
        //         "assunto": "apenas um teste",
        //         "tipo": "Incidente",
        //         "fila": "N2::DAU::Apoio ao Desenvolvimento Científico",
        //         "prioridade": "1 Muito Baixo",
        //         "a": "teste",
        //         "b": "teste"
        //     },
        //     "anexos": [
        //         {
        //             "contentType": "image/plain",
        //             "filename": "imagens.txt",
        //             "content": "SVNUTyBFIFVNIFRFU1RFCg=="
        //         }
        //     ]
        // }

        let anexos = [];
        if (atribuicoes["__anexos"]) {
            anexos = this.converterAnexos(atribuicoes["__anexos"]);
            delete atribuicoes["__anexos"];
        }

        const props = Object.fromEntries(
            Object.entries(atribuicoes).filter(
                ([key, value]) =>
                    key !== "__anexos" &&
                    value !== undefined &&
                    value != null &&
                    value.constructor !== Array
            )
        );

        const now = new Date();
        const propriedades = {
            assunto: `Chamado criado automaticamente - ${now.toLocaleString()}`,
            fila: "N1::Service Desk",
            tipo: "Requisição de Serviço",
            prioridade: "5 Muito Alto",
            ...props,
        };

        const tabelas = Object.fromEntries(
            Object.entries(atribuicoes).filter(
                ([key, value]) =>
                    key !== "__anexos" &&
                    value !== undefined &&
                    value != null &&
                    value.constructor === Array
            )
        );

        const headers = {
            "Content-Type": "application/json",
        };

        const data = {
            propriedades,
            anexos,
            tabelas,
        };

        return this.http
            .post("/api/ticket", data, { headers })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                throw error;
            });
    }

    /**
     * obtem lista de chamados
     * @param {
     *      idUsuario: id do usuário (geralmente o CPF),
     *      estado: aberto ou fechado
     * }
     */
    obterChamados({ idUsuario, estado, offset = 0, limit = 0 }) {
        return this.http
            .get(
                `/api/ticket?idUsuario=${idUsuario}&estado=${estado}&offset=${offset}&limit=${limit}`
            )
            .then((response) => response.data);
    }

    obterChamado(idTicket) {
        return this.http
            .get(`/api/ticket/${idTicket}`)
            .then((response) => response.data);
    }

    enviarResposta(idTicket, resposta) {
        const headers = {
            "Content-Type": "application/json",
        };

        return this.http
            .post(`/api/ticket/${idTicket}`, resposta, { headers })
            .then((response) => response.data);

        // return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //         const headers = {
        //             "Content-Type": "application/json",
        //         };

        //         this.http
        //             .post(`/api/ticket/${idTicket}`, resposta, { headers })
        //             .then((response) => resolve(response.data))
        //             .catch((err) => reject(err));
        //     }, 3000);
        // });
    }

    get(url) {
        return this.http.get(url);
    }

    post(url, data) {
        const headers = {
            "Content-Type": "application/json",
        };

        return this.http
            .post(url, data, { headers })
            .then((response) => response.data);
    }
}
