<template>
    <div>
        <v-dialog v-model="dialogo" persistent max-width="500">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="criarChamado"
                    tile
                    class="pa-6 mt-6"
                >
                    <v-icon>mdi-ticket-account</v-icon>
                    Criar Chamado
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="primary white--text">{{
                    titulo
                }}</v-card-title>
                <v-card-text
                    class="d-flex justify-center pa-16 mt-6"
                    v-if="loading"
                >
                    <spinner />
                </v-card-text>
                <v-card-text v-else class="mt-6">
                    <div v-if="!erro">
                        <p>
                            Chamado criado com o número de protocolo
                            <b>{{ tn }}</b
                            >.
                        </p>
                        <p v-if="prazo != null">
                            O prazo estimado de antendimento é até
                            <b>{{ prazo }}</b
                            >.
                        </p>
                    </div>
                    <div v-if="erro">
                        <p>
                            Ocorreu um problema em nossos servidores. Por favor,
                            tente mais tarde.
                        </p>
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="processar"
                        :disabled="loading"
                        >Ok</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Spinner from "../../../../componentes/ui/Spinner";

export default {
    components: {
        Spinner,
    },
    data() {
        return {
            loading: true,
            titulo: "Criando chamado...",
            dialogo: false,
            ticketId: "",
            tn: null,
            prazo: null,
            erro: false,
        };
    },
    methods: {
        ...mapActions("visualizacoes", ["atualizarVisualizacoes"]),
        criarChamado() {
            this.$servicoAtendimento
                .criarChamado()
                .then((data) => {
                    this.atualizarVisualizacoes();
                    this.mostrarMsgSucesso(data);
                })
                .catch((error) => {
                    this.mostrarMsgErro(error);
                });
        },
        mostrarMsgSucesso(data) {
            this.titulo = "Chamado criado com sucesso";
            this.ticketId = data.ticketId;
            this.tn = data.ticketNumber;
            if (
                data.solutionTimeDestinationDate !== null &&
                data.solutionTimeDestinationDate !== undefined &&
                data.solutionTimeDestinationDate !== ""
            ) {
                let date = new Date(data.solutionTimeDestinationDate);
                this.prazo = date.toLocaleString("pt-BR");
            }
            this.loading = false;
        },
        mostrarMsgErro(error) {
            console.log(error);
            this.titulo = "Falha ao criar chamado";
            this.erro = true;
            this.loading = false;
        },
        processar() {
            this.$servicoAtendimento.encerrarAtendimento();
            this.$router.push(`/chamados/detalhe/${this.ticketId}`);
        },
    },
};
</script>
