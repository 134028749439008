<template>
    <div class="d-flex align-center flex-column">
        <div
            v-for="(prop, index) in Object.keys(tabelas)"
            :key="index"
            class="ma-2 d-flex flex-column align-center"
        >
            <h3 class="text-capitalize">{{ prop }}</h3>
            <v-simple-table dense v-if="tabelas[prop].length > 0">
                <thead>
                    <tr>
                        <th
                            v-for="(valor, index) in Object.keys(
                                tabelas[prop][0]
                            )"
                            :key="index"
                            class="text-capitalize font-weight-bold"
                        >
                            {{ valor }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(linha, index) in tabelas[prop]" :key="index">
                        <td
                            v-for="(valor, indexLinha) in linha"
                            :key="indexLinha"
                        >
                            {{ valor }}
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
            <div v-else>Tabela vazia</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tabelas: {
            required: true,
        },
    },
    methods: {
        truncate(text) {
            if (text.length >= 40) {
                return text.slice(0, 38) + "...";
            } else {
                return text;
            }
        },
    },
};
</script>
