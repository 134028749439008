export default class ProcessadorInterpolacao {
    calcularValor({ operando, dados, atribuicoes }) {
        return operando.valor.replace(
            /(\$)\$\{([\w\s]+)\}|\$\{([\w\s]+)\}/g,
            (match, esc, chaveEsc, chave) => {
                // Se for escapado com $$, retornamos apenas ${chave} sem substituição
                if (esc === "$") {
                    return `\${${chaveEsc}}`;
                }
                if (chave in dados) {
                    return dados[chave];
                } else if (chave in atribuicoes) {
                    return atribuicoes[chave];
                } else {
                    return match;
                }
            }
        );
    }
}
