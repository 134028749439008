<template>
    <div class="d-flex align-center flex-column">
        <h3 v-if="Object.keys(atribuicoes).length > 0">Propriedades</h3>
        <v-simple-table dense class="mt-1">
            <tbody>
                <tr
                    v-for="(prop, index) in Object.keys(atribuicoes)"
                    :key="index"
                >
                    <td class="text-capitalize font-weight-bold text-right">
                        {{ truncate(prop) | capitalize }}
                    </td>
                    <td>{{ truncate(atribuicoes[prop]) }}</td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
export default {
    props: {
        atribuicoes: {
            required: true,
        },
    },
    methods: {
        truncate(text) {
            if (text.length >= 40) {
                return text.slice(0, 38) + "...";
            } else {
                return text;
            }
        },
    },
};
</script>
