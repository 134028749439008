<template>
    <div>
        <div class="ufv-btn-voltar">
            <v-row>
                <ViewBotaoVoltar @voltar="voltar" />
                <ViewBotaoCancelar @cancelar="cancelar" />
            </v-row>
        </div>
        <v-card>
            <div class="ufv-container-text">
                <div class="text-h4">{{ tela.titulo }}</div>
            </div>
            <v-container>
                <v-form ref="form" class="ufv-form">
                    <div v-for="(campo, nome) in tela.campos" :key="nome">
                        <div
                            v-for="erro in erros[nome]"
                            :key="erro"
                            class="red--text py-0"
                        >
                            {{ erro }}
                        </div>
                        <component
                            :is="$mapaCampos(campo.tipo)"
                            v-model="campos[nome]"
                            :campo="campo"
                        />
                    </div>

                    <div class="d-flex justify-center">
                        <v-btn
                            tile
                            color="primary"
                            @click="processar"
                            class="pa-6"
                        >
                            Avançar <v-icon>mdi-arrow-right-bold-box</v-icon>
                        </v-btn>
                    </div>
                </v-form>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import ViewBotaoVoltar from "@/views/atendimento/arvore/elementos/ViewBotaoVoltar";
import ViewBotaoCancelar from "./elementos/ViewBotaoCancelar.vue";

export default {
    name: "telaFormulario",
    emits: ["processar", "voltar", "cancelar"],
    props: {
        tela: {
            type: Object,
            required: true,
        },
        dados: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            campos: {},
            erros: {},
        };
    },
    components: {
        ViewBotaoVoltar,
        ViewBotaoCancelar,
    },
    created() {
        this.inicializarValores();
    },
    methods: {
        dadosVazios() {
            return Object.keys(this.dados).length === 0;
        },
        inicializarValores() {
            if (this.dadosVazios()) {
                this.inicializarPeloPadrao();
            } else {
                this.inicializarPelosDados();
            }
        },
        inicializarPeloPadrao() {
            Object.values(this.tela.campos).forEach(({ nome, valor }) => {
                this.setCampo(nome, valor);
            });
        },
        inicializarPelosDados() {
            Object.entries(this.dados).forEach(([nome, valor]) => {
                if (valor instanceof Array) {
                    this.setCampo(nome, [...valor]);
                } else {
                    this.setCampo(nome, valor);
                }
            });
        },
        setCampo(nome, valor) {
            if (!nome) {
                return;
            }
            // necessário setar assim para ficar reativo
            this.$set(this.campos, nome, valor);
        },
        validarCampos() {
            let retorno = true;
            retorno &= this.$refs.form.validate();

            this.erros = this.$verificador.validar(
                this.tela.campos,
                this.campos
            );

            retorno &= Object.keys(this.erros).every(
                (key) => this.erros[key].length === 0
            );

            return retorno;
        },
        processar() {
            if (this.validarCampos()) {
                this.$emit("processar", this.campos);
                this.campos = {};
                this.inicializarValores();
            }
        },
        voltar() {
            this.$emit("voltar");
        },
        cancelar() {
            this.$emit("cancelar");
        },
    },
};
</script>
<style scoped>
.ufv-form {
    margin-top: 3%;
    margin-left: 10%;
    margin-right: 10%;
}
</style>
