export default class ProcessadorAtribuicao {
    constructor({ mapaOperandos, mapaCondicoes }) {
        this.mapaOperandos = mapaOperandos;
        this.mapaCondicoes = mapaCondicoes;
    }

    avaliarCondicao({ atribuicao, dados }) {
        if (!atribuicao.condicao) {
            return true;
        }
        let tipoCond = atribuicao.condicao.tipo;
        let processadorCondicao = this.mapaCondicoes[tipoCond];
        let avaliacao = processadorCondicao.avaliar({
            dados,
            condicao: atribuicao.condicao,
        });
        return avaliacao;
    }

    atribuirValor({ atribuicao, dados, atribuicoes }) {
        let tipoOp = atribuicao.operando.tipo;
        let processadorOperando = this.mapaOperandos[tipoOp];
        let valor = processadorOperando.calcularValor({
            operando: atribuicao.operando,
            dados,
            atribuicoes,
        });

        atribuicoes[atribuicao.propriedade] = valor;
    }

    processar({ atribuicao, dados, atribuicoes }) {
        if (this.avaliarCondicao({ atribuicao, dados })) {
            this.atribuirValor({ atribuicao, dados, atribuicoes });
        }
    }
}
