<template>
    <div>
        <div class="ufv-btn-voltar">
            <v-row>
                <ViewBotaoVoltar @voltar="voltar" />
                <ViewBotaoCancelar @cancelar="cancelar" />
            </v-row>
        </div>
        <abrir-chamado
            v-if="!chamadoFechado"
            :atribuicoes="atribuicoes"
            :tabelas="tabelas"
            :anexos="anexos"
        />
        <informar-chamado-criado
            v-if="chamadoFechado"
        ></informar-chamado-criado>
    </div>
</template>
<script>
import ViewBotaoVoltar from "@/views/atendimento/arvore/elementos/ViewBotaoVoltar";
import ViewBotaoCancelar from "./elementos/ViewBotaoCancelar.vue";
import AbrirChamado from "./final/AbrirChamado.vue";
import InformarChamadoCriado from "./final/InformarChamadoCriado.vue";

export default {
    name: "telaFinal",

    components: {
        ViewBotaoVoltar: ViewBotaoVoltar,
        ViewBotaoCancelar: ViewBotaoCancelar,
        AbrirChamado,
        InformarChamadoCriado,
    },
    emits: ["voltar", "cancelar"],
    props: {
        tela: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            atribuicoes: [],
            tabelas: [],
            anexos: [],
        };
    },
    methods: {
        voltar() {
            this.$emit("voltar");
        },
        cancelar() {
            this.$emit("cancelar");
        },
        montarAtribuicoes() {
            const filtered = Object.entries(
                this.$store.getters["atendimento/atribuicoes"]
            ).filter(
                ([key, value]) =>
                    key !== "__anexos" &&
                    value !== undefined &&
                    value != null &&
                    value.constructor !== Array
            );
            this.atribuicoes = Object.fromEntries(filtered);
        },
        montarTabelas() {
            const filteredTabelas = Object.entries(
                this.$store.getters["atendimento/atribuicoes"]
            ).filter(
                ([key, value]) =>
                    key !== "__anexos" &&
                    value !== undefined &&
                    value != null &&
                    value.constructor === Array
            );
            this.tabelas = Object.fromEntries(filteredTabelas);
        },
        montarAnexos() {
            if (this.$store.getters["atendimento/atribuicoes"]["__anexos"]) {
                this.anexos =
                    this.$store.getters["atendimento/atribuicoes"]["__anexos"];
            }
        },
    },
    computed: {
        chamadoFechado() {
            return this.atribuicoes["state"] == "closed successful";
        },
    },
    mounted() {
        this.montarAtribuicoes();
        this.montarTabelas();
        this.montarAnexos();
    },
};
</script>
<style scoped>
* {
    word-break: break-word;
}
</style>
