<template>
    <v-card>
        <div class="ufv-container-text">
            <div class="text-h4">Abrir chamado</div>
        </div>
        <v-container>
            <div class="d-flex flex-column pa-4 align-center">
                <div class="text-wrap">
                    Para resolver seu problema, será necessário criar uma
                    solicitação em nosso sistema com os seguintes dados:
                </div>
                <propriedades :atribuicoes="atribuicoes" />
                <tabelas :tabelas="tabelas" />
                <anexos :anexos="anexos" />
                <dialogo-criar-chamado />
            </div>
        </v-container>
    </v-card>
</template>

<script>
import Propriedades from "./Propriedades.vue";
import Tabelas from "./Tabelas.vue";
import Anexos from "./Anexos.vue";
import DialogoCriarChamado from "./DialogoCriarChamado.vue";

export default {
    components: {
        Propriedades,
        Tabelas,
        Anexos,
        DialogoCriarChamado,
    },
    props: {
        atribuicoes: {
            required: true,
        },
        tabelas: {
            required: true,
        },
        anexos: {
            required: true,
        },
    },
    methods: {
        truncate(text) {
            if (text.length >= 40) {
                return text.slice(0, 38) + "...";
            } else {
                return text;
            }
        },
    },
};
</script>
