<template>
    <div>
        <v-dialog v-model="dialogo" persistent max-width="500">
            <v-card>
                <v-card-title class="primary white--text">{{
                    titulo
                }}</v-card-title>
                <v-card-text
                    class="d-flex justify-center pa-16 mt-6"
                    v-if="loading"
                >
                    <spinner />
                </v-card-text>
                <v-card-text v-else class="mt-6">
                    <div v-if="!erro">
                        <p>
                            Ficamos felizes que conseguiu resolver seu problema!
                            Registramos o atendimento com o número de protocolo
                            <b>{{ tn }}</b
                            >. Em breve, você receberá um e-mail com um link
                            para uma avaliação do atendimento. Sua colaboração é
                            muito importante!
                        </p>

                        <p>
                            Se precisar de algo mais, continuamos à disposição!
                        </p>
                    </div>
                    <div v-if="erro">
                        <p>
                            Ocorreu um problema em nossos servidores. Por favor,
                            tente mais tarde.
                        </p>
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="processar"
                        :disabled="loading"
                        >Ok</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Spinner from "../../../../componentes/ui/Spinner";

export default {
    components: {
        Spinner,
    },
    data() {
        return {
            dialogo: true,
            loading: true,
            erro: false,
            tn: "",
            ticketId: "",
            titulo: "Encerrando atendimento...",
        };
    },
    methods: {
        ...mapActions("visualizacoes", ["atualizarVisualizacoes"]),
        criarChamado() {
            this.$servicoAtendimento
                .criarChamado()
                .then((data) => {
                    this.atualizarVisualizacoes();
                    this.mostrarMsgSucesso(data);
                })
                .catch((error) => {
                    this.mostrarMsgErro(error);
                });
        },
        mostrarMsgSucesso(data) {
            this.titulo = "Atendimento encerrado";
            this.ticketId = data.ticketId;
            this.tn = data.ticketNumber;
            this.loading = false;
        },
        mostrarMsgErro(error) {
            console.log(error);
            this.titulo = "Falha ao criar chamado";
            this.erro = true;
            this.loading = false;
        },
        processar() {
            this.$servicoAtendimento.encerrarAtendimento();
            this.dialogo = false;
        },
    },
    mounted() {
        this.criarChamado();
    },
};
</script>
