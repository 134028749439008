<template>
    <v-btn color="primary" text @click="voltar()">
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("Voltar") }}
    </v-btn>
</template>

<script>
export default {
    name: "BtnVoltar",
    emits: ["voltar"],
    methods: {
        voltar() {
            this.$emit("voltar");
        },
    },
};
</script>
