<template>
    <v-container class="ufv-container-pages d-flex flex-column pt-0">
        <div class="ufv-container-text">
            <div class="text-h4">Chamados fechados</div>
        </div>
        <div class="chamados d-flex flex-row justify-center">
            <div
                v-if="loading"
                class="ufv-menu-loading my-8 align-self-stretch d-flex align-center"
            >
                <spinner />
            </div>
            <div v-if="!loading" class="my-6 flex-grow-1">
                <h2 class="ma-8" v-if="chamados.length === 0">
                    Você não possui nenhum Chamado Fechado
                </h2>
                <list-chamados
                    v-if="chamados.length > 0"
                    :chamados="chamados"
                    :pagina="pagina"
                    :totalItens="totalItens"
                    :itensPorPagina="itensPorPagina"
                    @mudarPagina="mudarPagina"
                />
            </div>
        </div>
        <div class="mt-8">
            <v-pagination
                :value="pagina"
                :length="numPaginas"
                :total-visible="7"
                @input="mudarPagina"
            />
        </div>
    </v-container>
</template>

<style scoped>
.chamados {
    min-height: 400px;
}
</style>

<script>
import { mapGetters } from "vuex";
import { INTERVALO_ATUALIZACAO_MS } from "@/utils/general";
import ListChamados from "@/componentes/chamados/ListChamados";
import Spinner from "@/componentes/ui/Spinner.vue";

export default {
    name: "ChamadosFechados",
    data() {
        return {
            loading: true,
            chamados: [],
            pagina: 1,
            itensPorPagina: 10,
            totalItens: 0,
            interval: null,
        };
    },
    components: {
        "list-chamados": ListChamados,
        Spinner,
    },
    computed: {
        ...mapGetters("autenticacao", ["cpf", "email", "clientName"]),
        idUsuario: function () {
            return this.clientName === "govbr" ? this.email : this.cpf;
        },
        offset: function () {
            return (this.pagina - 1) * this.itensPorPagina;
        },
        numPaginas: function () {
            return Math.ceil(this.totalItens / this.itensPorPagina);
        },
    },
    mounted() {
        this.pagina = this.$route.query.pagina
            ? parseInt(this.$route.query.pagina)
            : 1;
        this.itensPorPagina = this.$route.query.itensporpagina
            ? parseInt(this.$route.query.itensporpagina)
            : 10;
        this.carregarChamados();
        this.interval = setInterval(() => {
            this.carregarChamados();
        }, INTERVALO_ATUALIZACAO_MS);
    },
    destroyed() {
        clearInterval(this.interval);
    },
    methods: {
        carregarChamados() {
            this.loading = true;
            this.$servicoChamados
                .obterChamadosFechados({
                    idUsuario: this.idUsuario,
                    offset: this.offset,
                    limit: this.itensPorPagina,
                })
                .then((resposta) => {
                    this.totalItens = resposta.maxSize;
                    return resposta.tickets;
                })
                .then((chamados) => {
                    // chamados.sort((a, b) => (a.modificado < b.modificado ? 1 : -1));
                    this.chamados = chamados;
                    this.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.$router.push({ name: "500" });
                });
        },
        mudarPagina(novaPagina) {
            this.$router.replace({
                name: "ChamadosFechados",
                query: {
                    pagina: novaPagina,
                    itensporpagina: this.itensPorPagina,
                },
            });
            this.pagina = novaPagina;
            this.$route.query.pagina = novaPagina;
            this.carregarChamados();
        },
    },
};
</script>
