<template>
    <div class="d-flex align-center flex-column">
        <h3 v-if="anexos.length > 0">Anexos</h3>
        <v-simple-table dense class="ma-2" v-if="anexos.length > 0">
            <tbody>
                <tr v-for="anexo in anexos" :key="anexo.name">
                    <td class="text-capitalize font-weight-bold text-right">
                        {{ truncate(anexo.name) }}
                    </td>
                    <td>{{ truncate(anexo.type) }}</td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
export default {
    props: {
        anexos: {
            required: true,
        },
    },
    methods: {
        truncate(text) {
            if (text.length >= 40) {
                return text.slice(0, 38) + "...";
            } else {
                return text;
            }
        },
    },
};
</script>
