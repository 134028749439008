import ProcessadorTelaOpcao from "@/processadores/telas/ProcessadorTelaOpcao";
import ProcessadorTelaTutorial from "@/processadores/telas/ProcessadorTelaTutorial";
import ProcessadorTelaFormulario from "@/processadores/telas/ProcessadorTelaFormulario";
import ProcessadorAtribuicao from "@/processadores/ProcessadorAtribuicao";
import ProcessadorConstante from "@/processadores/operandos/ProcessadorConstante";
import ProcessadorVariavel from "@/processadores/operandos/ProcessadorVariavel";
import ProcessadorInterpolacao from "../../processadores/operandos/ProcessadorInperpolacao";
import ProcessadorCondicaoSimples from "@/processadores/condicoes/ProcessadorCondicaoSimples";
import ProcessadorCondicaoComposta from "@/processadores/condicoes/ProcessadorCondicaoComposta";
import ProcessadorFluxos from "@/processadores/ProcessadorFluxos";
import ProcessadorMapeamento from "@/processadores/ProcessadorMapeamento";
import MapeadorCamposTexto from "@/processadores/mapeamentos/MapeadorCamposTexto";
import MapeadorVazio from "@/processadores/mapeamentos/MapeadorVazio";
import MapeadorCaixaChecagem from "@/processadores/mapeamentos/MapeadorCaixaChecagem";
import ProcessadorAnexo from "@/processadores/ProcessadorAnexo";

export default class FabricaProcessadores {
    constructor() {
        this.processadorAtribuicao = null;
        this.mapaOperandos = null;
        this.mapaCondicoes = null;
        this.processadorFluxos = null;
        this.processadorMapeamento = null;
        this.processadorAnexo = null;
    }

    criarMapaOperandos() {
        if (!this.mapaOperandos) {
            const processadorConstante = new ProcessadorConstante();
            const processadorVariavel = new ProcessadorVariavel();
            const processadorInterpolacao = new ProcessadorInterpolacao();
            this.mapaOperandos = {
                constante: processadorConstante,
                variavel: processadorVariavel,
                interpolacao: processadorInterpolacao,
            };
        }
        return this.mapaOperandos;
    }

    criarMapaCondicoes() {
        if (!this.mapaCondicoes) {
            this.mapaCondicoes = {};

            const processadorCondicaoSimples = new ProcessadorCondicaoSimples({
                mapaOperandos: this.criarMapaOperandos(),
            });
            const processadorCOndicaoComposta = new ProcessadorCondicaoComposta(
                {
                    mapaCondicoes: this.mapaCondicoes,
                }
            );

            this.mapaCondicoes["simples"] = processadorCondicaoSimples;
            this.mapaCondicoes["composta"] = processadorCOndicaoComposta;
        }
        return this.mapaCondicoes;
    }

    criarProcessadorAtribuicao() {
        if (!this.processadorAtribuicao) {
            this.processadorAtribuicao = new ProcessadorAtribuicao({
                mapaOperandos: this.criarMapaOperandos(),
                mapaCondicoes: this.criarMapaCondicoes(),
            });
        }
        return this.processadorAtribuicao;
    }

    criarProcessadorFluxos() {
        if (!this.processadorFluxos) {
            this.processadorFluxos = new ProcessadorFluxos({
                mapaCondicoes: this.criarMapaCondicoes(),
            });
        }
        return this.processadorFluxos;
    }

    criarProcessadorMapeamento() {
        if (!this.processadorMapeamento) {
            var mapaMapeamentos = {};
            const mapeadorCamposTexto = new MapeadorCamposTexto();
            const mapeadorCaixaChecagem = new MapeadorCaixaChecagem();
            const mapeadorVazio = new MapeadorVazio();

            mapaMapeamentos["caixaDeChecagem"] = mapeadorCaixaChecagem;
            mapaMapeamentos["caixaDeSelecao"] = mapeadorCamposTexto;
            mapaMapeamentos["caixaDeTexto"] = mapeadorCamposTexto;
            mapaMapeamentos["memorando"] = mapeadorCamposTexto;
            mapaMapeamentos["texto"] = mapeadorVazio;
            mapaMapeamentos["caixaDeAnexo"] = mapeadorVazio;
            mapaMapeamentos["tabela"] = mapeadorCamposTexto;

            this.processadorMapeamento = new ProcessadorMapeamento({
                mapaMapeamentos,
            });
        }
        return this.processadorMapeamento;
    }

    criarProcessadorAnexo() {
        if (!this.processadorAnexo) {
            this.processadorAnexo = new ProcessadorAnexo();
        }
        return this.processadorAnexo;
    }

    criarProcessadorTelaOpcao() {
        return new ProcessadorTelaOpcao();
    }

    criarProcessadorTelaTutorial() {
        const processadorAtribuicao = this.criarProcessadorAtribuicao();
        return new ProcessadorTelaTutorial({ processadorAtribuicao });
    }

    criarProcessadorTelaFormulario() {
        return new ProcessadorTelaFormulario({
            processadorAtribuicao: this.criarProcessadorAtribuicao(),
            processadorFluxos: this.criarProcessadorFluxos(),
            processadorMapeamento: this.criarProcessadorMapeamento(),
            processadorAnexo: this.criarProcessadorAnexo(),
        });
    }

    criarProcessadores() {
        return {
            processadorTelaOpcao: this.criarProcessadorTelaOpcao(),
            processadorTelaTutorial: this.criarProcessadorTelaTutorial(),
            processadorTelaFormulario: this.criarProcessadorTelaFormulario(),
        };
    }
}
