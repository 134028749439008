/* eslint-disable no-console */

import store from "./vuex/store";

function updateServiceWorker(registration) {
    // Pede para o novo SW assumir o controle
    registration.waiting.postMessage({ type: "SKIP_WAITING" });

    // Aguarda ele ativar e então recarrega a página
    registration.waiting.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
            console.log("Nova versão ativa. Recarregando página...");
            window.location.reload();
        }
    });
}

if (process.env.NODE_ENV === "production") {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker
            .register("/service-worker.js")
            .then((registration) => {
                console.log("Service Worker registered:", registration);

                // Detecta se há um SW esperando para ser ativado (nova versão detectada)
                if (registration.waiting) {
                    console.log("Nova versão detectada. Atualizando...");
                    updateServiceWorker(registration);
                    return;
                }

                // Escuta quando uma nova versão é encontrada durante o uso
                registration.addEventListener("updatefound", () => {
                    const newWorker = registration.installing;

                    newWorker.addEventListener("statechange", () => {
                        if (
                            newWorker.state === "installed" &&
                            navigator.serviceWorker.controller
                        ) {
                            console.log(
                                "Nova versão instalada. Recarregando página..."
                            );
                            updateServiceWorker(registration);
                        }
                    });
                });
            });

        window.addEventListener("beforeinstallprompt", (event) => {
            store.dispatch("sw/setPrompt", { prompt: event });
            event.preventDefault();
        });
    }
}
