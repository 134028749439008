<template>
    <v-btn color="primary" text @click="cancelar()">
        <v-icon>mdi-cancel</v-icon>
        Cancelar
    </v-btn>
</template>

<script>
export default {
    name: "BtnCancelar",
    emits: ["cancelar"],
    methods: {
        cancelar() {
            this.$emit("cancelar");
        },
    },
};
</script>
