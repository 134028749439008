<template>
    <v-container class="ufv-container-pages d-flex flex-column pt-0">
        <div class="ufv-container-text">
            <div class="text-h4">{{ $t("Atendimento") }}</div>
            <div class="">{{ $t("EscolhaUmaOpcaoParaIniciar") }}</div>
        </div>
        <div v-if="!loading" class="my-3">
            <v-row dense>
                <v-col v-for="(arvore, i) in arvores" :key="i" :cols="colunas">
                    <Item :arvore="arvore" class="fill-height" />
                </v-col>
            </v-row>
        </div>
        <div v-else class="ufv-menu-loading d-flex justify-center">
            <spinner />
        </div>
    </v-container>
</template>

<script>
import Item from "./AtendimentoCard.vue";
import Utils from "@/utils/Utils";
import Spinner from "@/componentes/ui/Spinner.vue";

export default {
    name: "painel-servicos",
    components: {
        Item,
        Spinner,
    },
    data: () => ({
        arvores: [],
        loading: true,
    }),
    created() {
        this.$servicoAtendimento
            .getArvores()
            .then((nomeArvores) => {
                nomeArvores.forEach((nome) => {
                    this.arvores.push(nome);
                });
                this.loading = false;
            })
            .catch((error) => {
                console.log(error);
                this.$router.push(Utils.errorToRoute(error));
            });
    },
    computed: {
        colunas() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                case "sm":
                case "md":
                    return 12;
                default:
                    return 6;
            }
        },
    },
};
</script>
<style scoped>
.ufv-menu-loading {
    margin-top: 5%;
}
</style>
