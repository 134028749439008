<template>
    <div>
        <painel-servicos v-if="!atendimentoEmAberto" />
        <view-arvore v-if="atendimentoEmAberto" />
    </div>
</template>

<script>
import ViewArvore from "./arvore/ViewArvore.vue";
import PainelServicoes from "./PainelServicos.vue";
export default {
    components: {
        "painel-servicos": PainelServicoes,
        "view-arvore": ViewArvore,
    },
    name: "atendimento",
    computed: {
        atendimentoEmAberto: function () {
            return this.$servicoAtendimento.atendimentoEmAberto();
        },
    },
};
</script>
