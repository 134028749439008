<template>
    <transition name="slide-up">
        <div class="container" v-if="mostrar">
            <v-btn color="primary" elevation="10" @click="install"
                ><v-icon>mdi-download</v-icon>Instalar</v-btn
            >
            <v-btn
                elevation="10"
                icon
                small
                outlined
                color="primary"
                @click="cancel"
                ><v-icon>mdi-close</v-icon></v-btn
            >
        </div>
    </transition>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            timeout: false,
        };
    },
    computed: {
        ...mapGetters("sw", ["deferredPrompt", "mostrarPrompt"]),
        mostrar() {
            return this.mostrarPrompt && this.timeout;
        },
    },
    created() {
        setTimeout(() => {
            this.timeout = true;
        }, 5000);
    },
    methods: {
        ...mapActions("sw", ["clearPrompt"]),
        install() {
            this.deferredPrompt.prompt();
            this.deferredPrompt.userChoice.then(() => {
                // if (choiceResult.outcome === "accepted") {
                //     console.log("User accepted the A2HS prompt");
                // } else {
                //     console.log("User dismissed the A2HS prompt");
                // }
                this.clearPrompt();
            });
        },
        cancel() {
            this.clearPrompt();
        },
    },
};
</script>

<style scoped>
.container {
    /* background-color: rgba(200, 200, 200, 0.5); */
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

/* Transição de slide para cima */
.slide-up-enter-active,
.slide-up-leave-active {
    transition: transform 0.8s ease, opacity 0.8s ease;
}

.slide-up-enter,
.slide-up-leave-to {
    transform: translateY(100%);
    opacity: 0;
}

.slide-up-enter-to,
.slide-up-leave {
    transform: translateY(0);
    opacity: 1;
}
</style>
