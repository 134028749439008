import {
    SALVAR_ATENDIMENTO,
    ATUALIZAR_TELA_ATUAL,
    VOLTAR,
} from "@/vuex/mutations/atendimento";

const state = {
    atendimento: null,
};
const mutations = {
    [SALVAR_ATENDIMENTO]: (state, atendimento) => {
        state.atendimento = atendimento;
    },
    [ATUALIZAR_TELA_ATUAL]: (state, { proximaTela, item, atribuicoes }) => {
        state.atendimento.historico.inserir(item);
        state.atendimento.dados = {};
        state.atendimento.telaAtual = proximaTela;
        state.atendimento.atribuicoes = Object.assign({}, atribuicoes);
    },
    [VOLTAR]: (state) => {
        if (!state.atendimento.historico.podeVoltar()) {
            state.atendimento = null;
            return;
        }
        const item = state.atendimento.historico.voltar();
        state.atendimento.dados = item.dados;
        state.atendimento.telaAtual = item.nomeTela;
        state.atendimento.atribuicoes = item.atribuicoes;
    },
};
const getters = {
    telaAtual: (state) => {
        var nomeTela = state.atendimento.telaAtual;
        if (nomeTela === "#final") {
            return {
                tipo: "final",
            };
        }
        return state.atendimento.arvore.telas[nomeTela];
    },
    dados: (state) => {
        return state.atendimento.dados;
    },
    nomeTelaAtual: (state) => {
        return state.atendimento.telaAtual;
    },
    atribuicoes: (state) => {
        return state.atendimento.atribuicoes;
    },
    podeVoltar: (state) => {
        return state.atendimento.historico.podeVoltar();
    },
    emAberto: (state) => {
        return state.atendimento !== null;
    },
};
const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
