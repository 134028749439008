<template>
    <v-card color="primary" class="pt-2 pb-0 px-0" flat rounded="0">
        <v-container class="pb-0">
            <v-card-title class="d-flex justify-space-between">
                <div class="d-flex">
                    <v-img
                        class="ufv-toolbar-logo"
                        src="../../assets/logotipoUFV2.png"
                        alt="ufv"
                    />
                </div>

                <v-btn v-if="!isAuthenticated" small @click="login"
                    ><v-icon>mdi-login</v-icon>Entrar</v-btn
                >
                <div v-if="isAuthenticated">
                    <div class="d-flex flex-column align-end" v-if="!mobile">
                        <div
                            class="white--text my-2 text-caption text-capilize"
                        >
                            {{ username }}
                        </div>
                        <v-btn small @click="logout"
                            ><v-icon>mdi-logout</v-icon>Sair</v-btn
                        >
                    </div>
                    <v-menu v-if="mobile" bottom left offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on"
                                ><v-icon>mdi-menu</v-icon></v-btn
                            >
                        </template>
                        <v-list flat>
                            <v-subheader>{{ username }}</v-subheader>
                            <v-divider></v-divider>
                            <v-list-item link to="/">Home</v-list-item>
                            <v-divider></v-divider>
                            <v-list-item link to="/atendimento"
                                >Novo Chamado</v-list-item
                            >
                            <v-divider></v-divider>
                            <v-list-item link to="/chamados/abertos">
                                <v-badge
                                    inline
                                    :content="
                                        numAbertosNaoVisualizados + ' novos'
                                    "
                                    :value="numAbertosNaoVisualizados"
                                >
                                    Chamados Abertos
                                </v-badge>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item link to="/chamados/fechados"
                                ><v-badge
                                    inline
                                    :content="
                                        numFechadosNaoVisualizados + ' novos'
                                    "
                                    :value="numFechadosNaoVisualizados"
                                >
                                    Chamados Fechados
                                </v-badge></v-list-item
                            >
                            <v-divider></v-divider>
                            <v-list-item link @click="logout">Sair</v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-card-title>
            <v-tabs
                background-color="transparent"
                v-if="!mobile"
                active-class="ufv-app-bar-tab-active"
            >
                <v-tabs-slider color="#eee"></v-tabs-slider>
                <v-tab class="ufv-app-bar-tab" to="/">Home</v-tab>
                <v-tab
                    class="ufv-app-bar-tab"
                    to="/atendimento"
                    v-show="isAuthenticated"
                    >Novo Chamado</v-tab
                >
                <v-tab
                    class="ufv-app-bar-tab"
                    to="/chamados/abertos"
                    v-show="isAuthenticated"
                    ><v-badge
                        inline
                        :content="numAbertosNaoVisualizados + ' novos'"
                        :value="numAbertosNaoVisualizados"
                    >
                        Chamados Abertos
                    </v-badge></v-tab
                >
                <v-tab
                    class="ufv-app-bar-tab"
                    to="/chamados/fechados"
                    v-show="isAuthenticated"
                    ><v-badge
                        inline
                        :content="numFechadosNaoVisualizados + ' novos'"
                        :value="numFechadosNaoVisualizados"
                    >
                        Chamados Fechados
                    </v-badge></v-tab
                >
            </v-tabs>
        </v-container>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { LOGIN, LOGOUT } from "@/vuex/actions/autenticacao";
import { INTERVALO_ATUALIZACAO_MS } from "@/utils/general";

export default {
    data() {
        return {
            interval: null,
        };
    },
    mounted() {
        this.atualizarVisualizacoes();
        this.interval = setInterval(() => {
            this.atualizarVisualizacoes();
        }, INTERVALO_ATUALIZACAO_MS);
    },
    destroyed() {
        clearInterval(this.interval);
    },
    computed: {
        ...mapGetters("autenticacao", ["isAuthenticated", "username"]),
        ...mapGetters("visualizacoes", [
            "numAbertosNaoVisualizados",
            "numFechadosNaoVisualizados",
        ]),
        mobile: function () {
            return this.$vuetify.breakpoint.smAndDown;
        },
    },
    methods: {
        ...mapActions("autenticacao", {
            login: LOGIN,
            logout: LOGOUT,
        }),
        ...mapActions("visualizacoes", ["atualizarVisualizacoes"]),
    },
};
</script>
<style scoped>
/* .ufv-app-bar {
    padding-top: 1%;
    padding-left: 10%;
    padding-right: 10%;
} */
.ufv-toolbar-logo {
    max-width: 100px;
}
.ufv-app-bar-tabsX {
    margin-top: 10px;
    margin-left: 10%;
    margin-right: 10%;
}
.ufv-app-bar-tab {
    background-color: white;
    border-radius: 5px 5px 0px 0px;
    margin-right: 10px;
}

.ufv-app-bar-tab-active {
    background-color: #eee;
    color: #682a2c;
}
</style>
