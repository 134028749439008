var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.mobile)?_c('v-simple-table',{attrs:{"dense":"","fixed-header":""}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Chamado")]),_c('th',[_vm._v("Título")]),_c('th',[_vm._v("Criado")]),_c('th',[_vm._v("Modificado")])])]),_c('tbody',_vm._l((_vm.chamados),function(chamado){return _c('tr',{key:chamado.id,class:{
                    'font-weight-black': !chamado.visualizado,
                }},[_c('td',{staticClass:"d-flex flex-row align-end"},[_c('v-icon',[_vm._v(_vm._s(_vm.icon(chamado.estado)))]),_c('router-link',{staticClass:"mx-2",attrs:{"to":{
                            name: 'DetalheChamado',
                            params: { id: chamado.id },
                        }}},[_vm._v(_vm._s(chamado.tn))]),(!chamado.visualizado)?_c('v-icon',{attrs:{"color":"yellow","dark":""}},[_vm._v("mdi-star")]):_vm._e()],1),_c('td',[_vm._v(_vm._s(_vm.limite(chamado.titulo, 50)))]),_c('td',[_vm._v(_vm._s(_vm.formataData(chamado.criado)))]),_c('td',[_vm._v(" "+_vm._s(_vm.formataData(chamado.modificado))+" ")])])}),0)]):_vm._e(),(_vm.mobile)?_c('div',_vm._l((_vm.chamados),function(chamado){return _c('v-card',{key:chamado.id,staticClass:"d-flex mx-0 grey--text text--darken-2 mb-4 py-4 px-2",attrs:{"elevation":"1"}},[_c('v-icon',{staticClass:"text-h3 gray--text"},[_vm._v(_vm._s(_vm.icon(chamado.estado)))]),_c('div',{staticClass:"ml-4 flex-grow-1 flex-shrink-0 d-flex flex-column justify-space-around"},[_c('div',[_c('router-link',{attrs:{"to":{
                            name: 'DetalheChamado',
                            params: { id: chamado.id },
                        }}},[_vm._v(_vm._s(chamado.tn))])],1),_c('div',[_vm._v(" "+_vm._s(_vm.limite(chamado.titulo))+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" Criado: "+_vm._s(_vm.formataData(chamado.criado))+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" Modificado: "+_vm._s(_vm.formataData(chamado.modificado))+" ")])])],1)}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }