const ATUALIZAR_PROMPT = "atualizarPrompt";

const state = {
    deferredPrompt: null,
};

const getters = {
    deferredPrompt: (state) => state.deferredPrompt,
    mostrarPrompt: (state) => {
        return (
            state.deferredPrompt !== null &&
            !localStorage.getItem("promptCancelado")
        );
    },
};

const actions = {
    setPrompt: ({ commit }, { prompt }) => {
        commit(ATUALIZAR_PROMPT, { deferredPrompt: prompt });
    },
    clearPrompt: ({ commit }) => {
        localStorage.setItem("promptCancelado", true);
        commit(ATUALIZAR_PROMPT, { deferredPrompt: null });
    },
};

const mutations = {
    [ATUALIZAR_PROMPT]: (state, payload) => {
        state.deferredPrompt = payload.deferredPrompt;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
