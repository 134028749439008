<template>
    <v-container class="ufv-container-home pt-0">
        <div class="ufv-container-text">
            <div class="d-flex justify-center ufv-nome-sistema">
                <div>
                    Sistema de Abertura e Acompanhamento de Chamados da DTI
                </div>
                <v-chip
                    color="grey"
                    text-color="white"
                    small
                    pill
                    class="d-none d-lg-block"
                    >{{ version }}</v-chip
                >
            </div>
            <div class="d-flex d-lg-none justify-center">
                <v-chip color="grey" text-color="white" small pill>{{
                    version
                }}</v-chip>
            </div>
            <div class="text-h5 mt-8">Seja Bem-vindo!</div>
        </div>
        <v-btn v-if="!isAuthenticated" class="ufv-container-btn" @click="login"
            ><v-icon>mdi-login</v-icon> Entrar</v-btn
        >
        <v-btn
            v-if="isAuthenticated"
            class="ufv-container-btn"
            @click="iniciarAtendimento"
            >Iniciar Atendimento</v-btn
        >
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { LOGIN } from "@/vuex/actions/autenticacao";

export default {
    name: "Home",
    computed: {
        version() {
            return process.env.VUE_APP_VERSION || "0";
        },
        ...mapGetters("autenticacao", ["isAuthenticated"]),
    },
    methods: {
        ...mapActions("autenticacao", {
            login: LOGIN,
        }),
        iniciarAtendimento() {
            this.$servicoAtendimento.cancelar();
            this.$router.push("/atendimento");
        },
    },
};
</script>

<style scoped>
.ufv-nome-sistema {
    font-size: 45px;
    line-height: 45px;
    letter-spacing: 0;
}
@media screen and (max-width: 680px) {
    .ufv-nome-sistema {
        font-size: 30px;
        line-height: 30px;
        letter-spacing: 0;
    }
}
</style>
